import React from "react"
import { LocaleContext } from '../components/Layout'
import styled from '@emotion/styled'
import { CoolLink, CVbutton, Wrapper, DefaultHero, SEO } from '../components'

import { GitHub , Gmail, LinkedIn } from 'emotion-icons/simpleIcons'

const ContactWrapper = styled(Wrapper)`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: 4rem;
  
  > * :first-of-type {
    box-shadow: 9px 9px 14px ${props => props.theme.colors.bg_outset_1},
    -9px -9px 14px ${props => props.theme.colors.bg_outset_2};
  }
`

const ContactItems = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding-top: 1rem;
  
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-flow: column nowrap;
    align-items: center;
  }

  > * {
    padding: 0.5rem;
    box-shadow: 9px 9px 14px ${props => props.theme.colors.bg_outset_1},
    -9px -9px 14px ${props => props.theme.colors.bg_outset_2};
    border-radius: 0.5rem;
    margin: 0.5rem;
    width: fit-content;
  }

  a {
    color: ${props => props.theme.colors.text.p};
    font-family: 'Montserrat', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
  a::after {
    top: 80%;
    background-color: ${props => props.theme.colors.text.p};
  }
`

const Item = styled.div`
  display: flex;
  flex-flow: row nowrap;

  svg {
    margin: 0;
    margin-right: 0.5rem;
  }
`

const ContactPage = ({ pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <SEO
        title={`${i18n.pages.contact.name} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale}
        pageType={i18n.pages.home.name}
      />
      <DefaultHero>
        <Wrapper>
          <h1>{i18n.pages.contact.name}</h1>
        </Wrapper>
      </DefaultHero>
      <ContactWrapper>
        <CVbutton />
        <ContactItems>
          <Item>
            <Gmail/>
            <CoolLink>
              <a href="mailto:contact@ptalmeida.com">Email</a>
            </CoolLink>
          </Item>
          <Item>
            <GitHub/>
            <CoolLink>
              <a href="https://www.github.com/ptalmeida">Github</a>
            </CoolLink>
          </Item>
          <Item>
            <LinkedIn/>
            <CoolLink>
              <a href="https://www.linkedin.com/ptalmeida">LinkedIn</a>
            </CoolLink>
          </Item>
        </ContactItems>
      </ContactWrapper>
    </>
  )
}

export default ContactPage